import React from "react"
import classNames from "classnames"

import cardBackGreen from "./images/cards_01.png"
import cardBackBlue from "./images/cards_02.png"
import cardBlankGreen from "./images/cards_03.png"
import cardBlankBlue from "./images/cards_04.png"
import cardBlankOrange from "./images/cards_05.png"
import cardBlankBeige from "./images/cards_06.png"
import cardSelectionIndicator from "./images/cards_07.png"
import card8 from "./images/cards_08.png"
import card9 from "./images/cards_09.png"
import card10 from "./images/cards_10.png"
import card11 from "./images/cards_11.png"
import card12 from "./images/cards_12.png"
import card13 from "./images/cards_13.png"
import card14 from "./images/cards_14.png"
import card15 from "./images/cards_15.png"
import card16 from "./images/cards_16.png"
import card17 from "./images/cards_17.png"
import card18 from "./images/cards_18.png"
import card19 from "./images/cards_19.png"
import card20 from "./images/cards_20.png"
import card21 from "./images/cards_21.png"
import card22 from "./images/cards_22.png"
import card23 from "./images/cards_23.png"
import card24 from "./images/cards_24.png"
import card25 from "./images/cards_25.png"
import card26 from "./images/cards_26.png"
import card27 from "./images/cards_27.png"
import card28 from "./images/cards_28.png"
import card29 from "./images/cards_29.png"
import card30 from "./images/cards_30.png"
import card31 from "./images/cards_31.png"
import card32 from "./images/cards_32.png"
import card33 from "./images/cards_33.png"
import card34 from "./images/cards_34.png"
import card35 from "./images/cards_35.png"
import card36 from "./images/cards_36.png"
import card37 from "./images/cards_37.png"
import card38 from "./images/cards_38.png"
import card39 from "./images/cards_39.png"

import styles from "./Pairs.module.css"

const faceImages = {
  card8,
  card9,
  card10,
  card11,
  card12,
  card13,
  card14,
  card15,
  card16,
  card17,
  card18,
  card19,
  card20,
  card21,
  card22,
  card23,
  card24,
  card25,
  card26,
  card27,
  card28,
  card29,
  card30,
  card31,
  card32,
  card33,
  card34,
  card35,
  card36,
  card37,
  card38,
  card39,
}

const SCENES = {
  startup: "startup",
  mainMenu: "main-menu",
  inGame: "in-game",
  settingsMenu: "settings-menu",
  endGame: "end-game",
}

const DEFAULT_SCENE = SCENES.startup

function Startup({ onFinished }) {
  setTimeout(() => {
    onFinished()
  }, 1000)

  return <div>starting...</div>
}

function MainMenu({ onMenuItemClicked }) {
  const layoutSelectRef = React.createRef()

  return (
    <>
      <form name="config">
        <label>Select Layout: </label>
        <select name="tiles_layout" ref={layoutSelectRef}>
          <option value="3_2">3x2 (3 matches)</option>
          <option value="4_2">4x2 (4 matches)</option>
          <option value="4_3">4x3 (6 matches)</option>
          <option value="5_4">5x4 (10 matches)</option>
        </select>
      </form>
      <ul className={styles.mainMenu}>
        <li>
          <button
            className={classNames(styles.button, styles.startButton)}
            type="button"
            onClick={(e) => onMenuItemClicked(e, "start_game", { layoutSelectRef })}
          >
            start game
          </button>
        </li>
      </ul>
    </>
  )
}

class PairsGame extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gridRows: props.gridRows,
      gridColumns: props.gridColumns,
      tiles: {},
      faceValues: Object.keys(faceImages),
      isLoading: true,
      isMismatch: false,
      allTilesMatched: false,
      tilesLayout: props.tilesLayout,
    }
  }

  componentDidMount() {
    this.createTiles()
  }

  get revealedTiles() {
    let tiles = []

    for (let i in this.state.tiles) {
      if (this.state.tiles[i].isRevealed && !this.state.tiles[i].isMatched) {
        tiles.push(this.state.tiles[i])
      }
    }

    return tiles
  }

  createTiles = () => {
    let tiles = {}
    let tileId = ""
    let numFacesNeeded = (this.state.gridRows * this.state.gridColumns) / 2
    let chosenFaces = []
    let faceValues = [].concat(this.state.faceValues)

    // fill chosenFaces with all the cards
    for (let i = 0; i < numFacesNeeded; i++) {
      let idx = Math.floor(Math.random() * faceValues.length)
      let faceValue = faceValues[idx]
      console.log(idx, faceValue)
      chosenFaces.push(faceValue)
      chosenFaces.push(faceValue)

      faceValues.splice(idx, 1)
    }

    // shuffle chosen faces
    let tmpFaceValue = ""
    let a,
      b = 0

    for (let i = 0; i < numFacesNeeded * 3; i++) {
      a = Math.floor(Math.random() * chosenFaces.length)
      b = Math.floor(Math.random() * chosenFaces.length)

      tmpFaceValue = chosenFaces[b]
      chosenFaces[b] = chosenFaces[a]
      chosenFaces[a] = tmpFaceValue
    }

    const nextFaceValue = () => chosenFaces.pop()

    for (let y = 0; y < this.state.gridRows; y++) {
      for (let x = 0; x < this.state.gridColumns; x++) {
        tileId = `${x}-${y}`
        tiles[tileId] = {
          row: y,
          col: x,
          id: tileId,
          faceValue: nextFaceValue(),
          isRevealed: false,
          isMatched: false,
          isCollapsed: false,
        }
      }
    }

    this.setState({ tiles, isLoading: false, allTilesMatched: false })
  }

  handleTileClick = (e) => {
    if (this.coverTilesTimeout) return

    const id = e.currentTarget.dataset.id
    const tile = this.state.tiles[id]

    e.preventDefault()

    if (!tile) return console.error("no tile found with id ", id)

    if (tile.isRevealed || tile.isCollapsed) return

    this.setState(
      (state) => {
        state.tiles[id].isCollapsed = true
        return {
          tiles: state.tiles,
        }
      },
      () => {
        setTimeout(() => {
          this.setState(
            (state) => {
              state.tiles[id].isRevealed = true
              state.tiles[id].isCollapsed = false
              return {
                tiles: state.tiles,
              }
            },
            () => {
              console.log("revealed count ", this.revealedTiles.length)
              if (this.revealedTiles.length === 2) {
                this.checkRevealedTilesForMatch()
              }
            }
          )
        }, 350)
      }
    )
  }

  handlePlayAgainClick = (e) => {
    e.preventDefault()

    this.setState({ tiles: [], isLoading: true }, this.createTiles)
  }

  checkRevealedTilesForMatch() {
    let tiles = this.revealedTiles
    const isMatch = tiles[0].faceValue === tiles[1].faceValue

    if (isMatch) {
      this.setState((state) => {
        let allTilesMatched = true
        state.tiles[tiles[0].id].isMatched = true
        state.tiles[tiles[1].id].isMatched = true

        for (let i in state.tiles) {
          allTilesMatched = allTilesMatched && state.tiles[i].isMatched
        }

        return { tiles: state.tiles, allTilesMatched }
      })
    } else {
      this.setState({ isMismatch: true })

      this.coverTilesTimeout = setTimeout(() => {
        this.setState((state) => {
          state.tiles[tiles[0].id].isRevealed = false
          state.tiles[tiles[1].id].isRevealed = false
          state.tiles[tiles[0].id].hasBeenRevealed = true
          state.tiles[tiles[1].id].hasBeenRevealed = true

          this.coverTilesTimeout = null

          return { tiles: state.tiles, isMismatch: false }
        })
      }, 1000)
    }
  }

  render() {
    const renderedTiles = []
    let tile

    if (!this.state.isLoading) {
      for (let i in this.state.tiles) {
        tile = this.state.tiles[i]

        const faceImage = <img src={faceImages[tile.faceValue]} />
        const backImage = <img src={cardBackGreen} />

        renderedTiles.push(
          <button
            className={classNames(
              "animate__animated",
              styles.tile,
              styles[`row-${tile.row}`],
              styles[`col-${tile.col}`],
              {
                [styles.tileMatched]: tile.isMatched,
                [styles.collapsed]: tile.isCollapsed,
                animate__pulse: tile.isMatched,
                animate__headShake: !tile.isMatched && tile.isRevealed && this.state.isMismatch,
              }
            )}
            onClick={this.handleTileClick}
            data-id={tile.id}
            key={tile.id}
          >
            {tile.isRevealed || tile.isMatched ? faceImage : backImage}
          </button>
        )
      }
    }

    if (this.state.allTilesMatched) {
      console.log("All tiles matched! great jerb")
    }
    console.log(styles, this.state.tilesLayout)

    return (
      <div className={styles.gameArea}>
        <div className={classNames(styles.tilesGrid, styles[`tilesLayout${this.state.tilesLayout}`])}>
          {renderedTiles}
        </div>
        {this.state.allTilesMatched ? (
          <div className={styles.endGameScreen}>
            <p>great work!</p>
            <button onClick={this.handlePlayAgainClick} type="button">
              play again
            </button>
          </div>
        ) : null}
      </div>
    )
  }
}

export default class Pairs extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentScene: DEFAULT_SCENE,
      config: {},
    }
  }

  changeScene(newScene) {
    this.setState({
      currentScene: newScene,
    })
  }

  handleMainMenuItemClick = (e, itemName, data = {}) => {
    e.preventDefault()

    if (itemName === "start_game") {
      const config = {}
      config.tilesLayout = data.layoutSelectRef.current.value
      switch (config.tilesLayout) {
        case "3_2":
          config.gridRows = 3
          config.gridColumns = 2
          break
        case "4_2":
          config.gridRows = 4
          config.gridColumns = 2
          break
        case "4_3":
          config.gridRows = 4
          config.gridColumns = 3
          break
        case "5_4":
          config.gridRows = 5
          config.gridColumns = 4
          break
      }

      this.setState({ config }, () => {
        this.changeScene(SCENES.inGame)
      })
    }
  }

  render() {
    const { currentScene, config } = this.state
    let scene = null

    if (currentScene === SCENES.startup) {
      scene = <Startup onFinished={() => this.changeScene(SCENES.mainMenu)} />
    } else if (currentScene === SCENES.mainMenu) {
      scene = <MainMenu onMenuItemClicked={this.handleMainMenuItemClick} />
    } else if (currentScene === SCENES.inGame) {
      scene = <PairsGame {...config} />
    }

    return <div className="game-container__pairs">{scene}</div>
  }
}
