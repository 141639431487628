import React from "react"
import { Link } from "gatsby"

import PairsGame from "../pairs/Pairs"

import styles from "./pairs.module.css"

export default function PairsPage() {
  return (
    <div className={styles.page}>
      <div className={styles.headingContainer}>
        <Link to="/" className={styles.backLink}>
          &lt; back
        </Link>
        <h1 className={styles.heading}>Pairs</h1>
      </div>
      <PairsGame />
    </div>
  )
}
